import AllRoute from "./Routes/AllRoute";

function App() {
  return (
    <>
      <AllRoute />
    </>
  );
}

export default App;
